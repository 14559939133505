import React from 'react';
import Popup from './Popup';  

class Organigrama extends React.Component {
  constructor(props) {
    super(props);
    this.state = { iso: props.match.params.iso, showPopup: false, cargo: '', nombre : '', correo : '', imagen : '' };
    this.togglePopup = this.togglePopup.bind(this);
  }  
  togglePopup() {  
    this.setState({  
         showPopup: !this.state.showPopup
    });  
  }
  cargaDatos(value){
    switch(value) {
      case 'DG':
        this.setState({  
          cargo: 'Director General / Rector',
          nombre : 'Dr. Héctor Enrique Salgado Rodríguez',
          correo : 'direccion.general@tecmm.edu.mx',
          imagen: '/SGI/Fotos/salgado.png'
          });
          break;
      case 'PD':
        this.setState({  
          cargo: 'Director de Planeación y Desarrollo',
          nombre : 'Dr. Luis Fernando González Aldrete',
          correo : 'luisfernando.gonzalez@tecmm.edu.mx',
          imagen: '/SGI/Fotos/gonzalez.png'
          });
          break;
      case 'EV':
        this.setState({  
          cargo: 'Directora de Extensión y Vinculación',
          nombre : 'Mtra. María del Carmen Calva Tapia',
          correo : 'carmen.calva@tecmm.edu.mx',
          imagen: '/SGI/Fotos/calva.png'
          });
          break;
      case 'AF':
        this.setState({  
          cargo: 'Director de Administracion y Finanzas',
          nombre : 'L.C.P. Ricardo Ramos Dueñas',
          correo : 'ricardo.ramos@tecmm.edu.mx',
          imagen: '/SGI/Fotos/ramos.png'
          });
          break;
      case 'AII':
        this.setState({  
          cargo: 'Director de Área Académica, Investigación e Innovación',
          nombre : 'Mtro. Martín Villaseñor Flores',
          correo : 'martin.villasenor@tecmm.edu.mx',
          imagen: '/SGI/Fotos/villaseñor.png'
          });
          break;
    }
  }
  componentWillReceiveProps(nextProps) {
    // ¡Esto borrará cualquier actualización del estado local!
    // No lo hagas.
    this.setState({ iso: nextProps.match.params.iso });
  }
  render() {
    switch (this.state.iso) {
      case '9001':
        return <img src="/SGI/Organigramas/Org9001.png" className="img-fluid" alt="Organigrama" />;
      case '14001':
        return <img src="/SGI/Organigramas/Org14001.png" className="img-fluid" alt="Organigrama" />;
      case '45001':
        return <img src="/SGI/Organigramas/Org45001.png" className="img-fluid" alt="Organigrama" />;
      case '50001':
        return <img src="/SGI/Organigramas/Org50001.png" className="img-fluid" alt="Organigrama" />;
      case 'NMX':
        return <img src="/SGI/Organigramas/OrgNMX.png" className="img-fluid" alt="Organigrama" />;
      default:
        // return <img src="/SGI/Organigramas/OrgSGI.png" className="img-fluid" alt="Organigrama" />;
        return (
          <div>
            {/* https://www.image-map.net/ */}

            <img src="/SGI/Organigramas/OrgSGI.png" useMap="#image-map" />
            <map name="image-map">
              <area alt="DG" title="DG" coords="530,133,762,209" shape="rect" onClick={() => {this.togglePopup(); this.cargaDatos("DG");}} data-toggle="modal" data-target="#directorioModal" />
              <area alt="PD" title="PD" coords="404,251,613,324" shape="rect" onClick={() => {this.togglePopup(); this.cargaDatos("PD");}} data-toggle="modal" data-target="#directorioModal" />
              <area alt="EV" title="EV" coords="946,253,1155,328" shape="rect" onClick={() => {this.togglePopup(); this.cargaDatos("EV");}} data-toggle="modal" data-target="#directorioModal" />
            </map>

            <Popup
              cargo = {this.state.cargo}
              nombre = {this.state.nombre} 
              correo = {this.state.correo}
              imagen = {this.state.imagen} />
          </div>
        );
    }
  }
};

export default Organigrama;