import './verticalTab.css';
import './normas.css';
import React from 'react';
import { NavLink } from 'react-router-dom'

export default () => (
    <div className="container my-3">
        <div className="accordion" id="accordionExample">
            <div className="card-columns">
                <div className="card">
                    <div className="card-header bg-primary" id="headingCalidad">
                        <h2 className="mb-0">
                            <button className="btn btn-link btn-lg collapsed text-white" type="button" data-toggle="collapse" data-target="#calidad" aria-expanded="true" aria-controls="calidad">
                                Calidad - ISO 9001:2015
                        </button>
                        </h2>
                    </div>
                    <div id="calidad" className="collapse" aria-labelledby="headingCalidad" data-parent="#accordionExample">
                        <div className="card-body">
                            <h5 className="card-title text-success">Calidad - ISO 9001:2015</h5>
                            <p className="card-text">La Norma ISO 9001:2015 elaborada por la Organización Internacional para la Estandarización (International Standarization Organization o ISO por sus siglas en inglés), determina los requisitos para un Sistema de Gestión de la Calidad, que pueden utilizarse para su aplicación interna por las organizaciones, sin importar si el producto y/o servicio lo brinda una organización pública o empresa privada, cualquiera que sea su rama, para su certificación o con fines contractuales.</p>
                            <a className="btn btn-primary m-2" href="//sgi.tecmm.edu.mx/SGI/Normas/ISO_9001_2015.pdf" target="_blank">Ver Norma</a>
                            <NavLink className="btn btn-primary m-2" to="/Organigrama/9001" >Organigrama</NavLink>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header bg-success" id="headingEcologia">
                        <h2 className="mb-0">
                            <button className="btn btn-link btn-lg collapsed text-white" type="button" data-toggle="collapse" data-target="#ecologia" aria-expanded="false" aria-controls="ecologia">
                                Ecología - ISO 14001:2015
                        </button>
                        </h2>
                    </div>
                    <div id="ecologia" className="collapse" aria-labelledby="headingEcologia" data-parent="#accordionExample">
                        <div className="card-body">
                            <p className="card-text">La norma ISO 14001 es un conjunto de documentos de gestión ambiental que, una vez implantados, afectará todos los aspectos de la gestión de una organización en sus responsabilidades ambientales y ayudará a las organizaciones a tratar sistemáticamente asuntos ambientales, con el fin de mejorar el comportamiento ambiental y las oportunidades de beneficio económico. Los estándares son voluntarios, no tienen obligación legal y no establecen un conjunto de metas cuantitativas en cuanto a niveles de emisiones o métodos específicos de medir esas emisiones.</p>
                            <a className="btn btn-success m-2" href="//sgi.tecmm.edu.mx/SGI/Normas/ISO_14001_2015.pdf" target="_blank">Ver Norma</a>
                            <NavLink className="btn btn-success m-2" to="/Organigrama/14001" >Organigrama</NavLink>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header bg-warning" id="headingSalud">
                        <h2 className="mb-0">
                            <button className="btn btn-link btn-lg collapsed text-white" type="button" data-toggle="collapse" data-target="#salud" aria-expanded="false" aria-controls="salud">
                                Salud - ISO 45001:2018
                        </button>
                        </h2>
                    </div>
                    <div id="salud" className="collapse" aria-labelledby="headingSalud" data-parent="#accordionExample">
                        <div className="card-body">
                            <p className="card-text">La ISO 45001probará el compromiso con la seguridad y salud de trabajadores y partes interesadas.</p>
                            <a className="btn btn-warning m-2" href="#" target="_blank">Ver Norma</a>
                            <NavLink className="btn btn-warning m-2" to="/Organigrama/45001" >Organigrama</NavLink>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header bg-info" id="headingEnergia">
                        <h2 className="mb-0">
                            <button className="btn btn-link btn-lg collapsed text-white" type="button" data-toggle="collapse" data-target="#energia" aria-expanded="false" aria-controls="energia">
                                Energía - ISO 50001:2018
                        </button>
                        </h2>
                    </div>
                    <div id="energia" className="collapse" aria-labelledby="headingEnergia" data-parent="#accordionExample">
                        <div className="card-body">
                            <p className="card-text">ISO 50001 es una normativa internacional desarrollada por ISO (Organización Internacional para la Estandarización u Organización Internacional de Normalización) que tiene como objetivo mantener y mejorar un sistema de gestión de energía en una organización, cuyo propósito es el de permitirle una mejora continua de la eficiencia energética, la seguridad energética, la utilización de energía y el consumo energético con un enfoque sistemático. Este estándar apunta a permitir a las organizaciones mejorar continuamente la eficiencia, los costos relacionados con energía, y la emisión de gases de efecto invernadero.</p>
                            <a className="btn btn-info m-2" href="//sgi.tecmm.edu.mx/SGI/Normas/ISO_50001.pdf" target="_blank">Ver Norma</a>
                            <NavLink className="btn btn-info m-2" to="/Organigrama/50001" >Organigrama</NavLink>
                        </div>
                    </div>
                </div>
                <div className="card">
                    <div className="card-header bg-danger" id="headingIgualdad">
                        <h2 className="mb-0">
                            <button className="btn btn-link btn-lg collapsed text-white" type="button" data-toggle="collapse" data-target="#igualdad" aria-expanded="false" aria-controls="igualdad">
                                Igualdad laboral - NMX-R-025
                        </button>
                        </h2>
                    </div>
                    <div id="igualdad" className="collapse" aria-labelledby="headingIgualdad" data-parent="#accordionExample">
                        <div className="card-body">
                            <p className="card-text">La Norma Mexicana NMX-R-025-SCFI-2015 en Igualdad Laboral y No Discriminación es un mecanismo de adopción voluntaria para reconocer a los centros de trabajo que cuentan con prácticas en materia de igualdad laboral y no discriminación, para favorecer el desarrollo integral de las y los trabajadores.</p>
                            <a className="btn btn-danger m-2" href="//sgi.tecmm.edu.mx/SGI/Normas/NMX-R-025-SCFI-2015_2015_DGN.pdf" target="_blank">Ver Norma</a>
                            <NavLink className="btn btn-danger m-2" to="/Organigrama/NMX" >Organigrama</NavLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
);