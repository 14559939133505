import './App.css';
import header from './header.jpg';
import nombretecmm from './nombre-tecmm.jpg';
import footer from './footer.jpg';
import React from 'react';
import {Route} from 'react-router-dom'
import Nav from "./components/Navbar"
import identidad from './components/identidad'
import procesos from './components/procesos'
import normas from './components/normas'
import organigrama from './components/organigrama'
import Home from './components/home'
import { BrowserRouter } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={header} className="img-fluid" alt="header" />
        <img src={nombretecmm} className="img-fluid" alt="tecmm" />
        <div id="titulo" className="d-none d-md-block">
          <h2 className="font-weight-light text-warning">SISTEMA DE GESTIÓN INTEGRAL</h2>
        </div>
      </header>
      <BrowserRouter basename="#">
        <div className="w-100">
          <Nav />
          <Route exact path="/" component={Home} />
          <Route path="/Inicio" component={Home} />
          <Route path="/Identidad" component={identidad} />
          <Route path="/Organigrama/:iso" component={organigrama} />
          <Route path="/Procesos" component={procesos} />
          <Route path="/Normas" component={normas} />
          {/* <Route path="/Modal/:persona" component={modal} /> */}
          {/* <Route path="/Calidad" component={calidad} />
          <Route path="/Ecologia" component={ecologia} />
          <Route path="/Salud" component={salud} />
          <Route path="/Energia" component={energia} />
          <Route path="/Igualdad" component={igualdad} /> */}
        </div>
      </BrowserRouter>
      <footer className="App-footer">
        <img src={footer} className="img-fluid" alt="footer" />
        <div className="centered">
          <p>Camino Arenero 1101, Col. El Bajio<br></br>Zapopan, Jalisco, C.P. 45019</p>
        </div>
      </footer>
    </div>
  );
}

export default App;
