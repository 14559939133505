import React from 'react';   

class Popup extends React.Component {
    render() {  
        return (  
            <div className="modal fade show" id="directorioModal" tabIndex="-1" role="dialog" aria-labelledby="directorioModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-sm" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="directorioModalLabel">{this.props.cargo}</h5>
                            <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="col text-center mb-3">
                                <img src={this.props.imagen} className="img-thumbnail mx-auto d-block" width="200" height="=200" alt="salgado"/>
                                <br/><strong>{this.props.nombre}</strong>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <p>{this.props.correo}</p>
                        </div>
                    </div>
                </div>
            </div>
        );  
    }  
}  

export default Popup;