import './home.css';
import React from 'react';
import logo from '../LogoCelso.svg';

export default () => (
    <div className="container-fluid py-5">
        <div className="row">
            <div className="col-md-6 col-lg-7 my-5">
                <img src={logo} className="App-logo img-fluid m-auto d-block" alt="helice" />
            </div>
            <div className="col-md-6 col-lg-5">
                <p></p>
            </div>
        </div>
    </div>
);