import './Navbar.css';
import React from 'react';
import {NavLink, withRouter}  from 'react-router-dom'

class Navbar extends React.Component { 
    constructor(props) {
        super(props);
        this.state = {
          active: false
        };
      }
    render() {
        const active = (this.state.active) ? "show" : "hide" ;
        return (
            <nav className="navbar navbar-expand-md navbar-light bg-light sticky-top">
                <a href="#" className="navbar-brand mb-0 d-md-none"><span style={{fontVariant: 'small-caps',letterSpacing: '.5em'}} className="text-secondary">SGI</span></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" onClick={ () => this.setState({active: !this.state.active}) } aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation" style={{border: 'none'}}>
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className={"navbar-collapse collapse " + active} id="navbarNavDropdown">
                    <ul className="navbar-nav">
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/Inicio" onClick={ () => this.setState({active: !this.state.active}) } >Inicio</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/Identidad" onClick={ () => this.setState({active: !this.state.active}) } >Identidad</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/Procesos" onClick={ () => this.setState({active: !this.state.active}) } >Procesos Estratégicos</NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className="nav-link" to="/Normas" onClick={ () => this.setState({active: !this.state.active}) } >Normas</NavLink>
                        </li>
                    </ul>
                </div>
            </nav>
        )
    }
};
Navbar = withRouter(Navbar);
export default Navbar;