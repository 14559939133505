import './verticalTab.css';
import React from 'react';

export default () => (
    <div className="container-fluid my-3">
        <div className="vertical-tab">
            <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item">
                    <a className="nav-link active" id="aii-tab" data-toggle="tab" href="#aii" role="tab" aria-controls="aii" aria-selected="true">Académico, Investigación e Innovación</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="af-tab" data-toggle="tab" href="#af" role="tab" aria-controls="af" aria-selected="false">Administración y Finanzas</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="c-tab" data-toggle="tab" href="#c" role="tab" aria-controls="c" aria-selected="false">Calidad</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="pd-tab" data-toggle="tab" href="#pd" role="tab" aria-controls="pd" aria-selected="false">Planeación y Desarrollo</a>
                </li>
                <li className="nav-item">
                    <a className="nav-link" id="ve-tab" data-toggle="tab" href="#ve" role="tab" aria-controls="ve" aria-selected="false">Vinculación y Extensión</a>
                </li>
            </ul>
            <div className="tab-content" id="myTabContent">
                <div className="tab-pane fade show active" id="aii" role="tabpanel" aria-labelledby="aii-tab">
                    <h3>ACADEMICO, INVESTIGACION E INNOVACION</h3>
                    <div className="list-group">
                        <a href="\SGI\Procesos\ACADEMICO, INVESTIGACIÓN E INNOVACIÓN\TECNM-D-AC-PO-003_GESTION_DEL_CURSO_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para la Gestión del Curso
                        </a>
                        <a href="\SGI\Procesos\ACADEMICO, INVESTIGACIÓN E INNOVACIÓN\TECNM-D-AC-PO-005_EVAL_DOCENTE_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para la Evaluación Docente
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            Residencia
                        </a>
                        <a href="\SGI\Procesos\ACADEMICO, INVESTIGACIÓN E INNOVACIÓN\TECNM-D-AC-PO-009-DISENO_CURRICULAR_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para el Diseño, Evaluación y Seguimiento Curricular
                        </a>
                        <a href="\SGI\Procesos\ACADEMICO, INVESTIGACIÓN E INNOVACIÓN\TECNM-D-AC-PO-011_PROCEDIMIENTO_DE_TUTORIAS.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGC para aplicación de Tutorías 
                        </a>
                    </div>
                </div>
                <div className="tab-pane fade" id="af" role="tabpanel" aria-labelledby="af-tab">
                    <h3>ADMINISTRACION Y FINANZAS</h3>
                    <div className="list-group">
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AC-PO-001_INSCRIPCION_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para la inscripción
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AC-PO-002_REINSCRIPCION_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para la Reinscripción de alumnos
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AC-PO-004_TITULACION_ESTATAL_2004.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para la Titulación
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AC-PO-013_PROCEDIMIENTO_TITULACION_INTEGRAL_2009.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para la Titulación
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AD-IT-01_INTRUCTIVO_DE_COMPRAS_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Instructivo de Trabajo del SGI para la realización de Compras
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AD-PO-001_SERVICIOS_GENERALES_Y_MANTENIMIENTO.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para Servicios Generales, Mantenimiento Preventivo y/o Correctivo de la Infraestructura y Equipo
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AD-PO-002_INGRESOS_PROPIOS_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para la Captación de Ingresos Propios
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AD-PO-003_SELECCION_DEL_PERSONAL_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para el Reclutamiento, Selección y Contratación de Personal Docente y Administrativo
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AD-PO-004_CAPACITACION_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para la Capacitación del Personal 
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-AD-PO-005_AMBIENTE_LABORAL_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para Determinar y Gestionar el Ambiente de Trabajo
                        </a>
                        <a href="\SGI\Procesos\ADMINISTRACIÓN Y FINÁNZAS\TECNM-D-CA-IT-02_PROCESOS_EXTERNOS_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Instructivo de trabajo del SGI para la Identificación y Control de Procesos Externos
                        </a>
                    </div>
                </div>
                <div className="tab-pane fade" id="c" role="tabpanel" aria-labelledby="c-tab">
                    <h3>CALIDAD</h3>
                    <div className="list-group">
                        <a href="\SGI\Procesos\CALIDAD\Manual_Integral_ITS-Rev_12.doc" className="list-group-item list-group-item-action">
                            Manual Integral
                        </a>
                        <a href="\SGI\Procesos\CALIDAD\TECNM-D-CA-IT-01_INST_PARA_ELABORAR_PROCEDIMIENTOS.doc" className="list-group-item list-group-item-action">
                            Instructivo	de Trabajo del SGI para Elaborar Procedimientos
                        </a>
                        <a href="#" className="list-group-item list-group-item-action">
                            TECNM-D-CA-IT-03_REVISION_DIRECCION_ESTATAL
                        </a>
                        <a href="\SGI\Procesos\CALIDAD\TECNM-D-CA-PG-001_CONTROL_DE_DOCUMENTOS-Y-REGISTROS_ESTATAL-1.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para Control de los Documentos y Registros
                        </a>
                        <a href="\SGI\Procesos\CALIDAD\TECNM-D-CA-PG-003_AUDITORIA_INTERNA_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para Auditoría Interna
                        </a>
                        <a href="\SGI\Procesos\CALIDAD\TECNM-D-CA-PG-004_CONTROL_DEL_SNC_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para el Control del Servicio No Conforme
                        </a>
                        <a href="\SGI\Procesos\CALIDAD\TECNM-D-CA-PO-001_QUEJAS_Y_SUGERENCIAS_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para Atención de Quejas y/o Sugerencias
                        </a>
                        <a href="\SGI\Procesos\CALIDAD\TECNM-D-PROCESO-DE-ACCIONES-CORRECTIVAS-O-PREVENTIVAS.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para Acciones Correctivas y Preventivas
                        </a>
                    </div>
                </div>
                <div className="tab-pane fade" id="pd" role="tabpanel" aria-labelledby="pd-tab">
                    <h3>PLANEACION Y DESARROLLO</h3>
                    <div className="list-group">
                        <a href="\SGI\Procesos\PLANEACIÓN Y DESARROLLO\TECNM-D-PL-PO-001_PROCEDIMIENTO_MIR_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI de Matriz de Indicadores de Resultados
                        </a>
                    </div>
                </div>
                <div className="tab-pane fade" id="ve" role="tabpanel" aria-labelledby="ve-tab">
                    <h3>VINCULACION Y EXTENSION</h3>
                    <div className="list-group">
                        <a href="\SGI\Procesos\VINCULACIÓN Y EXTENSIÓN\TECNM-D-VI-PO-001_VISITAS_A_EMPRESAS_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para Visitas a Empresas
                        </a>
                        <a href="\SGI\Procesos\VINCULACIÓN Y EXTENSIÓN\TECNM-D-VI-PO-002_PROC_DE_SERVICIO_SOCIAL_ESTATAL-1.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para Servicio Social
                        </a>
                        <a href="\SGI\Procesos\VINCULACIÓN Y EXTENSIÓN\TECNM-D-VI-PO-003_PROMOCION_CULT-DEPORTIVA_ESTATAL.doc" className="list-group-item list-group-item-action">
                            Procedimiento del SGI para cultura, deporte y recreación
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
);