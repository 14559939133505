import './verticalTab.css';
import React from 'react';

export default () => (
  <div className="container-fluid my-3">
    <div className="vertical-tab">
        <ul className="nav nav-tabs" id="myTab" role="tablist">
            <li className="nav-item">
                <a className="nav-link active" id="mision-tab" data-toggle="tab" href="#mision" role="tab" aria-controls="mision" aria-selected="true">Misión</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="vision-tab" data-toggle="tab" href="#vision" role="tab" aria-controls="vision" aria-selected="false">Visión</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="valores-tab" data-toggle="tab" href="#valores" role="tab" aria-controls="valores" aria-selected="false">Valores</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="fines-tab" data-toggle="tab" href="#fines" role="tab" aria-controls="fines" aria-selected="false">Objetivos y Fines</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="organigrama-tab" data-toggle="tab" href="#organigrama" role="tab" aria-controls="organigrama" aria-selected="false">Organigrama</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="funciones-tab" data-toggle="tab" href="#funciones" role="tab" aria-controls="funciones" aria-selected="false">Funciones</a>
            </li>
            <li className="nav-item">
                <a className="nav-link" id="directorio-tab" data-toggle="tab" href="#directorio" role="tab" aria-controls="directorio" aria-selected="false">Directorio</a>
            </li>
        </ul>
        <div className="tab-content" id="myTabContent">
            <div className="tab-pane fade show active" id="mision" role="tabpanel" aria-labelledby="mision-tab">
                <h3>MISIÓN</h3>
                <p>“Brindar educación integral de clase mundial para formar profesionistas competitivos, innovadores y emprendedores y satisfacer las demandas de desarrollo científico y tecnológico del sector productivo en cada una de las regiones del estado de Jalisco con proceso de investigación aplicada y transferencia de tecnología”.</p>
            </div>
            <div className="tab-pane fade" id="vision" role="tabpanel" aria-labelledby="vision-tab">
                <h3>VISIÓN</h3>
                <p>“Ser reconocido por su liderazgo en la economía del conocimiento nacional, formando los profesionistas más talentosos, innovadores y emprendedores del país y tener los más altos índices de investigación e innovación tecnológica, así como de la producción y transferencia de propiedad intelectual entre las instituciones de educación superior de México”.</p>
            </div>
            <div className="tab-pane fade" id="valores" role="tabpanel" aria-labelledby="valores-tab">
                <h3>VALORES</h3>
                <p>
                    <strong>Bienestar Social.</strong> Mejorar el acceso, la cobertura y la calidad de la educación superior, reducir el rezago educativo y promover la equidad en las oportunidades educativas.
                    <br /><br />
                    <strong>Sustentabilidad.</strong> Compromiso con la economía y la ecología de sostenimiento a lo largo del tiempo sin agotar los recursos o perjudicar el medio ambiente.
                    <br /><br />
                    <strong>Integridad.</strong> La capacidad de actuar en consecuencia con lo que se dice o lo que se considera que es importante, tiene todas sus partes enteras y no afecta los intereses de las personas ni la institución.
                    <br /><br />
                    <strong>Innovación.</strong> De manera continua buscamos introducir en todos los ámbitos del quehacer de nuestro Instituto, cambios que representen una mejora, un progreso y sobre todo un mayor valor para los receptores de nuestros servicios y productos (clientes internos y externos) y de todo nuestro ecosistema.
                    <br /><br />
                    <strong>Competitividad.</strong> Es la cualidad con la cual se busca la realización de los objetivos y generar los mejores resultados educativos, generando conocimiento para el desarrollo de las personas y su entorno.
                    <br /><br />
                    <strong>Emprendimiento.</strong> Generar actividades y estrategias para establecer el ambiente emprendedor, estimulando la iniciativa de los estudiantes para desarrollar un proyecto de negocio, generando el sentido de emprendimiento y generación de ideas creativas viables, para que se conviertan en una realidad.
                </p>
            </div>
            <div className="tab-pane fade" id="fines" role="tabpanel" aria-labelledby="fines-tab">
                <h3>OBJETIVOS Y FINES</h3>
                <p>Con fundamento en los articulos 7° y 8° los objetivos y fines del Instituto son los siguientes:</p>
                <h5 className="font-weight-bold text-warning">Objetivos</h5>
                <p>
                    <strong>I.</strong> Formar profesionales de nivel superior que requiere el desarrollo del Estado de Jalisco y del país;
                    <br /><br />
                    <strong>II.</strong> Realizar labores de investigación científica y tecnológica;
                    <br /><br />
                    <strong>III.</strong> Extender los beneficios de la ciencia y de la tecnológia hacia la sociedad;
                    <br /><br />
                    <strong>IV.</strong> Promover entre los miembros de su comunidad académica una formación armónica y equilibrada;
                    <br /><br />
                    <strong>V.</strong> Participar en el proceso de creación, conservación, renovación y transmisión de la cultura de la ciencia y la tecnología; y
                    <br /><br />
                    <strong>VI.</strong> Los demás que señalen las leyes y reglamentos aplicables.
                </p>
                <h5 className="font-weight-bold text-warning text-underline">Fines</h5>
                <p>
                    Son fines del instituto promover el bienestar de los habitantes del Estado de Jalisco, contribuir al desarrollo social, económico y cultural de la comunidad, así como la conservación y aprovechamiento sostenible de sus recursos naturales.
                    <br /><br />
                    El instituto aplicará los principios de solidaridad, justicia y democracia entre todos los integrantes de su comunidad y en su relación con la sociedad.
                </p>
            </div>
            <div className="tab-pane fade" id="organigrama" role="tabpanel" aria-labelledby="organigrama-tab">
                <h3>ORGANIGRAMA</h3>
                <img src="./SGI/Organigramas/OrgSGI.png" className="img-fluid" alt="Organigrama" useMap="#planetmap"/>
                <map name="planetmap">
                    <area shape="rect" coords="100,100,200,200" alt="Sun" href="#salgado"></area>
                    <area shape="circle" coords="90,58,3" alt="Mercury" href="#"></area>
                    <area shape="circle" coords="124,58,8" alt="Venus" href="venus.htm"></area>
                </map>
            </div>
            <div className="tab-pane fade" id="funciones" role="tabpanel" aria-labelledby="funciones-tab">
                <h3>FUNCIONES</h3>
                <p>“FUNCIONES”.</p>
            </div>
            <div className="tab-pane fade" id="directorio" role="tabpanel" aria-labelledby="directorio-tab">
                <h3>DIRECTORIO</h3>
                <div className="container">
                    <div className="row">
                        <div className="col text-center mb-3">
                            <img src="./SGI/Fotos/salgado.png" className="img-thumbnail mx-auto d-block" width="200" height="=200" alt="salgado"/>
                            <br/><strong>Dr. Héctor Enrique Salgado Rodríguez</strong>
                            <br/>Director General / Rector
                            <br/>direccion.general@tecmm.edu.mx
                        </div>
                        <div className="col text-center mb-3">
                            <img src="./SGI/Fotos/gonzalez.png" className="img-thumbnail mx-auto d-block" width="200" height="=200" alt="gonzalez"/>
                            <br/><strong>Dr. Luis Fernando González Aldrete</strong>
                            <br/>Director de Planeación y Desarrollo
                            <br/>luisfernando.gonzalez@tecmm.edu.mx
                        </div>
                        <div className="col text-center mb-3">
                            <img src="./SGI/Fotos/calva.png" className="img-thumbnail mx-auto d-block" width="200" height="=200" alt="calva"/>
                            <br/><strong>Mtra. María del Carmen Calva Tapia</strong>
                            <br/>Directora de Extensión y Vinculación
                            <br/>carmen.calva@tecmm.edu.mx
                        </div>
                        <div className="col text-center mb-3">
                            <img src="./SGI/Fotos/ramos.png" className="img-thumbnail mx-auto d-block" width="200" height="=200" alt="ramos"/>
                            <br/><strong>L.C.P. Ricardo Ramos Dueñas</strong>
                            <br/>Director de Administracion y Finanzas
                            <br/>ricardo.ramos@tecmm.edu.mx
                        </div>
                        <div className="col text-center mb-3">
                            <img src="./SGI/Fotos/villaseñor.png" className="img-thumbnail mx-auto d-block" width="200" height="=200" alt="villaseñor"/>
                            <br/><strong>Mtro. Martín Villaseñor Flores</strong>
                            <br/>Director de Área Académica, Investigación e Innovación
                            <br/>martin.villasenor@tecmm.edu.mx
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
);